$colors: (
    blueKF: #38a3fd,
    volcanoKF: #ff7a45,
    orangeKF: #ffa940,
    dastredKF: #f5222d,
    goldKF: #faad14,
    yellowKF: #fadb14,
    limeKF: #7cb305,
    greenKF: #237804,
    cyanKF: #08979c,
    geekblueKF: #2f54eb,
    purpilKF: #722ed1,
    magentaKF: #eb2f96
);

.colors{
    margin-bottom: 10px; 
    display: flex;
    flex-wrap: wrap;   
    .color{
        width: 25px;
        height: 25px;
        margin: 3px;
        border-radius: 50%; 
        cursor: pointer; 
        box-shadow: 0 2px 2px rgba(0,0,0,0.3);  
    }
}


@mixin setColor($color: #38a3fd, $name: "blueKF") {
    .#{$name}-color {
        background: $color;
        border: 2px solid $color;
        box-shadow: 0 8px 10px rgba($color, 0.3);
        -webkit-animation: #{$name} linear 1s infinite;
        animation: #{$name} linear 1s infinite;
        .text-call {
            span {
                color: #fff;
            }
        }
        &:hover {
            background: #fff;
            color: transparent;
            .text-call {
                span {
                    color: $color;
                }
            }
        }
        @keyframes #{$name} {
            0% {
                box-shadow: 0 8px 10px rgba($color, 0.3), 0 0 0 0 rgba($color, 0.2), 0 0 0 0 rgba($color, 0.2);
            }
            40% {
                box-shadow: 0 8px 10px rgba($color, 0.3), 0 0 0 15px rgba($color, 0.2), 0 0 0 0 rgba($color, 0.2);
            }
            80% {
                box-shadow: 0 8px 10px rgba($color, 0.3), 0 0 0 30px rgba($color, 0), 0 0 0 26.7px rgba($color, 0.067);
            }
            100% {
                box-shadow: 0 8px 10px rgba($color, 0.3), 0 0 0 30px rgba($color, 0), 0 0 0 40px rgba($color, 0);
            }
        }
    }
   
}
@each $name, $color in $colors {
    @include setColor($color, $name);
     .color{
        &.#{$name} {
            background-color: $color;
        }
    }
}
