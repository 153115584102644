$height: 105px;

.zs-button {
    border-radius: 50%;    
    cursor: pointer;
    height: $height;
    text-align: center;
    width: $height;    
    transition: 0.3s;    
    .text-call {
        height: $height;
        width: $height;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            text-align: center;
            opacity: 1;
            line-height: 14px;
            font-weight: 600;
            text-transform: uppercase;
            transition: opacity 0.3s linear;
            font-family: "montserrat", Arial, Helvetica, sans-serif;
            opacity: 1;
            font-size: 11px;
        }
    }
    &:hover{
        z-index: 1;
        transition: 0.3s;
    }
}
