html,
body,
.App,
#root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
#root {
    overflow: hidden;
    position: relative;
}
.App {
    iframe {
        width: 100%;
        height: 100%;
    }
}
.settings { 
    width: 100%;
    background-color: white;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    transition: left 0.3s ease;
    box-sizing: border-box;
}
.controls {
  margin-bottom: 10px;
  display: flex;
  .button{
    background-color: #eb2f96;
    color: white;
    padding: 5px 20px;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    &:hover{
      background-color: lighten($color: #eb2f96, $amount: 10);
    }
    @media (max-width: 640px){
      display: none;
    }
  }
}

.inputs{
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width:200px;
  margin-left: 20px;
  height: 25px;
  padding: 3px 10px;
}

.language-markup{
  font-size: 0.8em;
}

.settings-button{
  position: fixed;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  left: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0,0,0,0.3);  
}
.ant-drawer{
  /* width: 100%!important;
  max-width: 450px; */
}

textarea.ant-input{
  font-size: 11px;
  font-family: 'Courier New', Courier, monospace;
}