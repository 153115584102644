.zs-bottom-right-position{
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 999;
    @media (max-width: 640px){
        right: 30px;
        bottom: 30px;
    }
}

.zapros-frame{
    .zs-button{
        display: none;
    }
}